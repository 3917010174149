<template>
  <div class="privacy" v-html="text_privacy_policy"></div>
</template>

<script>

import axios from "axios";
import config from "@/store/config";
import bus from "@/store/bus";

export default {
  name: 'Privacy',
  data() {
    return {
      text_privacy_policy: ""
    }
  },
  mounted() {
    bus.$on("re_render", this.refreshData);
    this.getPage();
  },
  methods : {
    refreshData: function () {
      this.getPage();
    },
    getPage : function () {
      axios({
        method: "POST",
        url:
            config.getters.mainUrl + "service/translate?ts=" + Math.round(+new Date() / 1000),
        data: {
          page: "privacy_policy",
          lng: this.$cookie.get("elza_lng")
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            if (
                result != undefined &&
                result.data != undefined &&
                result.data.result != undefined
            ) {
              if (result.data.result == true) {
                this.text_privacy_policy = result.data.data;
              }
            }
          },
          error => {
            console.log(error);
          },
      );
    }
  }
}
</script>
